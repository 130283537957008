import { Title } from '@solidjs/meta';
import { For, Show, createSignal } from 'solid-js';
import { createAsync, Navigate, useLocation } from '@solidjs/router';
import {
	BreadcrumbItem,
	Button,
	Container,
	Errors,
	FieldDescription,
	Form,
	Heading,
	Input,
	Label,
	Link,
	Page,
	Section,
	TextField,
	TextLink,
} from '@troon/ui';
import { Icon } from '@troon/icons';
import { useTrackEvent } from '@troon/analytics';
import { useUser } from '../../../providers/user';
import NotFound from '../../_/404';
import { Hero } from '../../../components/hero/photo';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import { cachedQuery } from '../../../graphql/cached-get';
import { Benefit, gql, mutationAction, TroonCardSubscriptionProductType, useMutation } from '../../../graphql';
import type { RouteDefinition } from '@solidjs/router';

export default function GolfPassBenefit() {
	const user = useUser();
	const loc = useLocation();
	const [copied, setCopied] = createSignal(false);
	const track = useTrackEvent();

	const claimedCode = createAsync(() => getClaimedCode({}), { deferStream: true });
	const claimAction = useMutation(claimCodeMutation);

	return (
		<Show when={user()} fallback={<Navigate href={`/auth?redirect=${loc.pathname}`} />}>
			<Show
				when={user()?.activeTroonCardSubscription?.productType === TroonCardSubscriptionProductType.TroonAccessPlus}
				fallback={<NotFound />}
			>
				<Title>Golf Pass | Benefits | Troon Access | Troon</Title>
				<Hero
					src="https://images.ctfassets.net/rdsy7xf5c8dt/3ie01KCCQJGXZ1E1Mmeg5N/4da5a321ea5f80ef8cb92131087de506/golf-pass-hero.png"
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/access">Troon Access</BreadcrumbItem>
							<BreadcrumbItem href="/access/benefits">Benefits</BreadcrumbItem>
							<BreadcrumbItem href="/access/benefits/golf-pass">GolfPass+</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1">GolfPass+</Heading>
					<p class="text-lg">One year of GolfPass+ for free! (over $200 value)</p>
				</Hero>

				<Container>
					<Page>
						<Grid>
							<GridSeven>
								<Section>
									<Heading as="h2">Overview</Heading>
									<p>
										GolfPass+ members save hundreds* every year on tee times and live golf tournament coverage. New
										members get benefits to help them play more and save more through GolfNow with waived booking fees,
										Tee Time Protection, and $10 monthly tee time credits (that’s $120 a year). Plus, get on-demand
										lessons, access to Golf Channel classics and GolfPass originals, like The Conor Moore show and Ask
										Rory. Plus, members get access to stream live sporting events on Peacock, along with news, exclusive
										shows, hit movies, and more!
									</p>

									<Heading as="h3" size="p">
										Included with GolfPass+
									</Heading>
									<ul class="flex list-disc flex-col gap-2 ps-6">
										<li>Waived fees for 10 tee time bookings on GolfNow (up to 4 players per booking)</li>
										<li>$10 monthly tee time credits ($120 per year) for bookings on GolfNow</li>
										<li>
											GolfNow Tee Time Protection on up to 10 bookings (up to 1 hour prior to tee time, for any reason)
										</li>
										<li>1 year of Peacock included ($79.99 value)</li>
										<li>Earn and redeem GolfPass Points for discounted or free tee times on GolfNow</li>
									</ul>

									<p class="text-sm italic text-neutral-800">
										*GolfPass+ benefits are for NBC Sports NEXT properties only, including GolfNow. For support and
										questions on your free GolfPass+ membership and/or benefits, please{' '}
										<TextLink href="https://www.golfpass.com/contact-us">contact GolfPass</TextLink>
									</p>

									<p class="text-sm italic text-neutral-800">
										To book your Access+ rates at Troon courses, use our booking experience at{' '}
										<TextLink href="/">troon.com</TextLink>. For courses outside our network, you can leverage your
										GolfPass+ benefits at <TextLink href="https://golfnow.com">golfnow.com</TextLink>
									</p>
								</Section>
							</GridSeven>

							<GridFive>
								<Section class="sticky top-24 gap-4 rounded border border-neutral p-4 md:p-6">
									<Heading as="h2" size="h4">
										How to Redeem
									</Heading>

									<Show
										when={claimedCode()?.codes.length && claimedCode()?.codes}
										fallback={
											<Form action={claimAction} document={claimQuery}>
												<p>Request your exclusive promo code to get one year free of GolfPass+</p>
												<Errors />
												<Button type="submit">Claim your GolfPass+ code</Button>
											</Form>
										}
									>
										{(codes) => (
											<>
												<p>
													Copy your exclusive promo code and follow the link below to get one year free of GolfPass+
												</p>

												<For each={codes() as Array<{ code: string }>}>
													{(code) => (
														<div class="flex gap-4">
															<TextField name="code" class="grow">
																<Label class="sr-only">Your unique GolfPass+ redemption code</Label>
																<Input readonly value={code.code} />
																<FieldDescription>
																	<Show when={copied()} fallback={<>&nbsp;</>}>
																		<p class="text-brand-600">Code has been copied to your clipboard!</p>
																	</Show>
																</FieldDescription>
															</TextField>
															<Button
																onClick={() => {
																	window.navigator.clipboard.writeText(code.code);
																	setCopied(true);
																}}
																class="size-fit shrink grow-0"
															>
																<Icon name="copy" />
																<span class="sr-only">Copy 0234TROON</span>
															</Button>
														</div>
													)}
												</For>

												<Button
													as={Link}
													href="https://www.golfpass.com/troon"
													appearance="secondary"
													onClick={() => {
														track('redeemBenefit', { benefit: Benefit.Golfpass });
													}}
												>
													Redeem Your Code <Icon name="external-link" />
												</Button>
											</>
										)}
									</Show>
								</Section>
							</GridFive>
						</Grid>
					</Page>
				</Container>
			</Show>
		</Show>
	);
}

const claimedQuery = gql(`query claimedBenefitCode {
  codes: getClaimedBenefitCodes(benefit: GOLFPASS) { code }
}`);
const getClaimedCode = cachedQuery(claimedQuery);

const claimQuery = gql(`mutation claimBenefitCode {
  code: claimBenefitCode(benefit: GOLFPASS) { code }
}`);
const claimCodeMutation = mutationAction(claimQuery, {
	revalidates: ['claimedBenefitCode'],
	track: {
		event: 'claimBenefit',
		data: { benefit: Benefit.Golfpass },
	},
});

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
